import $http from './axios.js';

var transformRequest = function(data) {
  // 对 data 进行任意转换处理
  if (!data) {
    return data;
  }
  var props = '';
  // 开始遍历
  for (var p in data) {
    // 方法
    if (props.length > 0) {
      props += '&'
    }
    // p 为属性名称，obj[p]为对应属性的值
    props += p + '=' + data[p];

  }
  // return encodeURIComponent(encodeURI(props));
  return props;
}

class Http {

  get(url, param) {
    url += '?sid=' + localStorage.getItem('sid')
    if (param) {
      url += '&' + transformRequest(param)
    }
    return new Promise((resolve, reject) => {
      $http.get(url).then(response => {
//console.log(response)
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  post(url, params) {
    if (!params) {
      params = {}
    }
    params.sid = localStorage.getItem('sid')
    return new Promise((resolve, reject) => {
      $http.post(url, params).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  post(url, params, config) {
    if (!params) {
      params = {}
    }
    params.sid = localStorage.getItem('sid')

    return new Promise((resolve, reject) => {
      $http.post(url, params, config).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  put(url, params) {

    return new Promise((resolve, reject) => {
      $http.put(url, params).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    })
  }

  delete(url, params) {
    return new Promise((resolve, reject) => {
      $http.delete(url, params).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    })
  }

  // delete(url, laddaDom) {
  //   let ladda;
  //   if (laddaDom) {
  //     ladda = Ladda.create(laddaDom);
  //     ladda.start();
  //   }
  //   return new Promise((resolve, reject) => {
  //     $http.delete(url).then(response => {
  //       if (ladda) {
  //         ladda.stop();
  //       }
  //       resolve(response);
  //     }).catch(error => {
  //       if (ladda) {
  //         ladda.stop();
  //       }
  //       reject(error);
  //     });
  //   })
  // }

}

export default new Http();
