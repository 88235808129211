<template>
  <div class="container">
    <div>
      <!-- <Logo /> -->

      <div class="shop-body">
        <div class="pay-title">请输入支付金额</div>
        <div class="section__pay">
          <input type="digit" class="pay__input" v-model="payInput" bindinput="handleZanFieldChange" placeholder="输入支付金额"
            auto-focus />
        </div>
        <div class="pay__btns">
          <button @click="createOrder">支付</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import http from '../utils/http/http.js'
// const ap = require('../js/alipayjsapi.js').ap

export default {
  data () {
    return {
      payInput: '',
      sid: null,
      code: null,
      shopCode: null,
      hash: 'ac8936e',
      shopId: null
    }
  },

  mounted () {
    // console.log(this.$route.query.shopcode)
    this.hash = this.$route.query.hash
    this.shopCode = this.$route.query.shopcode
    // alert(self.code)
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js'
    // s.src = '../js/alipayjsapi.min.js'
    document.body.appendChild(s)
    // let   url= "https://qr.alipay.com/bax05143urq7ugqip0s100f3";
    // window.open(url,"_self");
    // this.authcode()
  },
  methods: {
    createOrder: function () {
      console.log('createOrder')
      if (!this.sid) {
        this.login(this.createOrder)
        return
      }
      const self = this
      http.post('orders/alipay-create-h5', {
        amount: self.payInput,

        code: self.shopCode
      }).then(result => {
        console.log(result)
        window.open(result, '_blank')
      })
    },
    login: function (callback) {
      console.log('login')
      const self = this
      if (!self.code) {
        this.authcode(this.login, callback)
        return
      }
      http.post('ali-users/h5-login', {
        hash: self.hash,
        code: self.code
      }).then(result => {
        self.sid = result.sid
        localStorage.setItem('sid', self.sid)
        callback()
      })
    },

    authcode: function (callback, param) {
      console.log('authcode')

      const self = this

      // self.code = 'e276d1eeee934430b943a2773babXX97'
      // callback(param)
      // return
      // const ap = {}
      ap.getAuthCode({
        appId: '2021001198611094',
        scopes: ['auth_base']
      }, function (res) {
        // alert(JSON.stringify(res))
        let code = res.authCode
        if (!code) {
          code = res.authcode
        }
        if (code) {
          self.code = code
          // self.payInput = code
          callback(param)
        }
      })
    }
  }

}
</script>

<style>
  .container {
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    text-align: center;
  }

  .pay__input {
    border: 1px solid #4b0;
    padding: 20px;
    height: 100px;
    border-radius: 6px;
    font-size: 44px;
    width: 90%;
  }

  .pay-title {
    font-size: 14px;
    color: #333333;
    padding-left: 30px;
    margin-bottom: 20px;
  }

  .pay__btns {
    padding: 40px 0px;

  }

  button {
    position: relative;
    display: block;
    padding-left: 14px;
    padding-right: 14px;
    box-sizing: border-box;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    line-height: 2.55555556;
    border-radius: 5px;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    color: white;
    background-color: #00ee00;
  }
</style>
