import axios from 'axios';
import host from './host.js';
//import {MessageBox} from 'mint-ui';

var COMMON_SUCCESS = 'success';
var COMMON_ERROR = 'error';
var COMMON_USER_INVALID = 'need_login';
var baseUrl = host.host;
// 超时时间
axios.defaults.timeout = 30000;
axios.defaults.baseURL = baseUrl; //外网
let instance = axios.create({
  transformRequest: function(data) {
    // 对 data 进行任意转换处理
    if (!data) {
      return data;
    }
    var props = '';
    // 开始遍历
    for (var p in data) {
      // 方法
      if (props.length > 0) {
        props += '&'
      }
      // p 为属性名称，obj[p]为对应属性的值
      props += p + '=' + data[p];

    }
    return props;
  }
})
instance.interceptors.request.use(config => {
  try {
    // if (plus) {
    //   console.log('token:' + plus.storage.getItem('token'));
    //   console.log('config.url:' + config.url);
    //   config.headers['token'] = plus.storage.getItem('token');
    // }
  } catch (e) {
//console.log('catch token:' + localStorage.token);
    config.headers['token'] = localStorage.token;
  }

  return config
}, error => {
  // 加载超时
  return Promise.reject(error)
});
// http响应拦截器
instance.interceptors.response.use(data => {
// console.log('interceptors:'+data);
  if (data.data.success) {

    disLoading();
    return data.data.data;
  } else if (data.data.code == 10) {
    localStorage.removeItem('session')

    return Promise.reject(data.data.msg);
  } else if (data.data.msg) {
    disLoading();

    showNotify(data.data.msg);
//console.log('出错了', data.data.msg);
    return Promise.reject(data.data.msg);
  } else {

    return Promise.reject();
  }
}, error => {
  disLoading();
  return Promise.reject(error);
});


function showNotify(content) {
 // MessageBox('提示', content)
}

function disLoading(content) {
  // try {
  //   vue.disLoading();
  // } catch (e) {

  // }
}

// export default axios
export default instance
