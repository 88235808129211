class Host {
  host_files = 'https://city.free-coins.cn/city/files/'
  host =  'https://city.free-coins.cn/city-api/'
}
class Host1 {
  host_files = 'https://k.cityets.com/city/files/'
  host =  'http://localhost/city-api/'
}
class Host2 {
  host_files = 'http://192.168.101.71/city/files/'
  host =  'http://192.168.3.5:8081/city-api/'
}

class Host3 {
  host_files = 'http://192.168.101.16/city/files/'
  host =  'http://192.168.101.16/city-api/'
}
class Host4 {
  host_files = 'http://192.168.0.102/city/files/'
  host =  'http://192.168.0.102/city-api/'
}


export default new Host();
